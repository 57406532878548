// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../packages/pwa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../packages/pwa/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../packages/pwa/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./IBMPlexMono-Regular.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: \"IBM Plex Mono\";\n    font-style: normal;\n    font-weight: 400;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n}\n", "",{"version":3,"sources":["webpack://./../../purekeep/assets/fonts/IBMPlexMono/IBMPlexMono.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,gBAAgB;IAChB,4DAAqD;AACzD","sourcesContent":["@font-face {\n    font-family: \"IBM Plex Mono\";\n    font-style: normal;\n    font-weight: 400;\n    src: url(./IBMPlexMono-Regular.woff2) format(\"woff2\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
