// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../packages/pwa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../packages/pwa/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../packages/pwa/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./OpenSans-Light.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./OpenSans-Regular.woff2";
import ___CSS_LOADER_URL_IMPORT_2___ from "./OpenSans-SemiBold.woff2";
import ___CSS_LOADER_URL_IMPORT_3___ from "./OpenSans-Bold.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: \"Open Sans\";\n    font-style: normal;\n    font-weight: 300;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n}\n@font-face {\n    font-family: \"Open Sans\";\n    font-style: normal;\n    font-weight: 400;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");\n}\n@font-face {\n    font-family: \"Open Sans\";\n    font-style: normal;\n    font-weight: 600;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");\n}\n@font-face {\n    font-family: \"Open Sans\";\n    font-style: normal;\n    font-weight: 700;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\");\n}\n", "",{"version":3,"sources":["webpack://./../../purekeep/assets/fonts/OpenSans/OpenSans.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB;IAChB,4DAAgD;AACpD;AACA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB;IAChB,4DAAkD;AACtD;AACA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB;IAChB,4DAAmD;AACvD;AACA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB;IAChB,4DAA+C;AACnD","sourcesContent":["@font-face {\n    font-family: \"Open Sans\";\n    font-style: normal;\n    font-weight: 300;\n    src: url(./OpenSans-Light.woff2) format(\"woff2\");\n}\n@font-face {\n    font-family: \"Open Sans\";\n    font-style: normal;\n    font-weight: 400;\n    src: url(./OpenSans-Regular.woff2) format(\"woff2\");\n}\n@font-face {\n    font-family: \"Open Sans\";\n    font-style: normal;\n    font-weight: 600;\n    src: url(./OpenSans-SemiBold.woff2) format(\"woff2\");\n}\n@font-face {\n    font-family: \"Open Sans\";\n    font-style: normal;\n    font-weight: 700;\n    src: url(./OpenSans-Bold.woff2) format(\"woff2\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
