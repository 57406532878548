export enum Events {
    Logout = "pm_logout",
    LoginApp = "pm_enter_masterpassword",
    LoginFA = "pm_login",
    ViewItems = "pm_view_vaults",
    OpenVault = "pm_open_vault",
    CreateVault = "pm_create_vault",
    CreateAccount = "pm_create_masterpassword",
    ClickRenew = "pm_expiry_renew_clicked",
    ClickAddNewItem = "pm_add_item",
    DesiredOutcomeShown = "pm_DO_shown",
    DesiredOutcomeSubmit = "pm_DO_submit",
    FeedbackFormShown = "pm_feedback_shown",
    FeedbackFormOpen = "pm_feedback_open",
    FeedbackFormSubmit = "pm_feedback_submit",
}
